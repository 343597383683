#sidebar{
    display: flex;
    flex-direction: column;
    row-gap: 2.5vh;
    align-items: center;
    justify-content: flex-start;

    position: relative;
    height: 100%;
    width: 18%;
    z-index: 300;
    padding: 3vh 2vw 1.5vh 2vw;

    /* background-color: cornflowerblue; */
    background-color: var(--sidebar);
    box-shadow: 5px 0px 15px rgb(0, 0, 0, 0.3);

    font-size: 1.6rem;

    overflow-y: auto;
    overflow-x: hidden;
}



#sidebarHeader #name{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1vh;
}

#sidebarHeader hr{
    display: block;
    color: var(--dark-font);
    border-style: solid;
    border-width: 1px;

    margin: 1.5vh 0vw;
}

#name,
#nameSubhead{
    margin: 0;
    /* color: var(--white-bg-font); */
    color: var(--dark-font);
}

#name{
    text-align: center;
    font-size: clamp(3.3rem, 2.4vw, 4rem);
}

#nameSubhead{
    font-weight: 500;
    text-align: center;
    font-size: clamp(1.5rem, 1.4vw, 2.3rem);
}

#portrait{
    width: auto;
    max-width: 80%;
    height: auto;
    /* aspect-ratio: 1/1; */

    border-radius: 10px;
    overflow: hidden;
}

#bio{
    hyphens: auto;
    font-weight: 400;
    text-align: justify;
    color: var(--dark-font);
    font-size: clamp(1.4rem, 1.5vw , 1.7rem);
    white-space: pre-line;
    line-height: 1.5;
}
/* #bio::before{
    content: "\00a0 \00a0 \00a0 ";
} */

#buttons{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
}


#contactButton{
    box-sizing: border-box;
    width: 60%;
    padding: 1vh 0.3vw;

    font-weight: 500;
    font-size: clamp(2rem, 1.5vw, 2.4rem);

    background-color: var(--tab-4);
    color: var(--white-bg-font);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    user-select: none;
}

#mailIcon{
    width: 1vw;
    height: auto;
    filter: invert(100%);
}

#langSelect{
    user-select: none;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    margin-top: 4vh;
}

#langSelect img{
    width: 3vw;
    height: auto;
    aspect-ratio: 4/3;
}

.hover-button{
    transition: filter 200ms, transform 200ms;
}
.hover-button:hover{
    filter: drop-shadow(0px 2px 3px rgb(0,0,0,0.8));
    transform: scale(110%);
}

@media screen and (max-width: 800px) {
    #sidebar{
        width: 100%;
        height: 15vh;
        overflow: hidden;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: auto;

        padding: 2vh 3vh;


        box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.3);

    }
    #sidebarHeader hr{
        margin: 1vh 0vw;
    }

    #name{
        text-align: center;
        font-size: clamp(2.5rem, 2vw, 3rem);
    }
    
    #nameSubhead{
        font-weight: 500;
        text-align: center;
        font-size: clamp(1.6rem, 1.4vw, 2.4rem);
    }

    #sidebar #portrait,
    #sidebar #bio{
        display: none;
    }

    #sidebar #buttons{
        margin-top: 0;
        width: 36%;
        height: fit-content;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 1ch;
        align-items: center;
    }

    #contactButton{
        width: 100%;
        font-size: clamp(1.8rem, 1.6vw, 2.2rem);
    }
    

    #sidebar #mailIcon{
        margin-left: 0vh;
        width: 2.4vh;
    }

    #langSelect{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: fit-content;
        margin-top: 0vh;

        column-gap: 1vh;
    }
    #langSelect button{
        width: 35%;
        height: fit-content;
    }
    #langSelect img{
        height: auto;
        width: 100%;
        aspect-ratio: 4/3;
    }
    .hover-button:hover{
        filter: none;
        transform: scale(100%);
    }
    .hover-button:active{
        filter: drop-shadow(0px 2px 3px rgb(0,0,0,0.8));
        transform: scale(110%);
    }
}