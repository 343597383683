.dropdown-select{
    position: relative;
    width: 100%;

    cursor: pointer;
    user-select: none;
}

.dropdown-base{
    background-color: coral;
    padding: 1vh;
    width:100%;
}
.dropdown-base:hover{
    background-color: aqua;
}

.dropdown-active-hidden{
    height: 0px;
    overflow: hidden;
}

.dropdown-active-menu{
    z-index: 2000;
    position: absolute;
    width: 100%;
    background-color: coral;
    box-shadow: 0px 4px 3px rgb(0, 0, 0, 0.3);
    max-height: 65vh;
    overflow-y: auto;
    scrollbar-width: thin;
}

.dropdown-option{
    padding: 1vh;
}
.dropdown-option:hover{
    background-color: aqua;
}