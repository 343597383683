/**************************Main Tab Behavior*******************************/
.tab {
    display: flex;
    position: relative;
    flex: 1 1 auto;
    transition: flex 400ms ease-in-out, background-color 400ms ease-in-out;

    overflow: hidden;

    font-size: 1.6rem;

    box-shadow: -5px 0px 15px rgb(0, 0, 0, 0.3);
}

.tab-unopened {
    flex-basis: 25%;
}

.tab-unopened:hover {
    flex-basis: 30%;
}

.tab-opened {
    flex-basis: 75%;
}

.tab-minimized {
    flex: 1 1 3%;
}

.tab-minimized:hover {
    flex-grow: 1.3;
}


/*Behavior of the title of the tab when default, opened and minimized*/
.tab-title {
    text-align: center;
    pointer-events: none;
    user-select: none;

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0;
    left: 0;


    visibility: visible;
    transition: visibility 200ms ease-out, opacity 200ms ease-out;

    margin: 0;
}

.tab-title span {
    display: block;
    width: fit-content;
    height: fit-content;
    font-size: 3rem;

    text-transform: uppercase;

    transition: transform 200ms, font-size 200ms;

    color: var(--white-bg-font);
    text-shadow: 0px 0px 5px black;

    font-size: 4rem;
}

.tab-minimized .tab-title span {
    transform: rotate(-90deg);
    font-size: 2vw;
}

.tab-opened .tab-title {
    visibility: collapse;
    opacity: 0;
}

/*****************Tab Content Behavior*********************/
.tab-content {
    position: relative;

    background-color: var(--white-bg-font);

    scrollbar-width: thin;

    overflow-y: scroll;
    overflow-x: hidden;

    padding: 2vh 2vw 2vh 2vw;

    pointer-events: stroke;

    width: 65vw;
    height: 100%;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    transition: opacity 400ms ease-in-out, transform 400ms ease-in-out, visibility 400ms ease-in-out;


}

.tab-overflow-inner {
    height: fit-content;
    position: relative;
}

.tab-unopened .tab-content,
.tab-minimized .tab-content {
    visibility: hidden;
    opacity: 0;
    transform: translateX(3vw);
}


.tab-opened .tab-content {
    visibility: visible;

    opacity: 1;
    transform: translateX(0vw);
}

/*******************Individual Tab Behavior***************/

#skills {
    /*background-color:darkseagreen;*/
    background-color: var(--tab-4);
    z-index: 700;
}

#skills .scrolltop {
    background-color: var(--tab-4);
}

#portfolio {
    /* background-color: indianred; */
    background-color: var(--tab-3);
    z-index: 800;
}

#portfolio .scrolltop {
    background-color: var(--tab-3);
}


#experience {
    /* background-color: mediumslateblue; */
    background-color: var(--tab-2);
    z-index: 900;
}

#experience .scrolltop {
    background-color: var(--tab-2);
}


#other {
    /* background-color: moccasin; */
    background-color: var(--tab-1);
    z-index: 1000;
}

#other .scrolltop {
    background-color: var(--tab-1);
}


#skills.tab-opened,
#portfolio.tab-opened,
#experience.tab-opened,
#other.tab-opened {
    background-color: var(--white-bg-font);
}



/*SCROLL UP BTN*/

.scrolltop {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vh;

    position: sticky;
    bottom: 0vh;
    left: 100%;

    background-color: var(--white-bg-font);

    visibility: hidden;

    transition: visibility 600ms, transform 500ms ease-out;

    box-shadow: 0px 2px 3px rgb(0, 0, 0, 0.8);

    transform: translateY(130%);
}

.scrolltop img {
    width: 2vw;
    aspect-ratio: 1/1;
}

.scroll-btn-display {
    visibility: visible;
    transform: translateY(0%);
}

@media screen and (max-width:800px) {

    /* MOBILE TAB TITLE BEHAVIOR */
    .tab{
        box-shadow: 0px -5px 15px rgb(0, 0, 0, 0.3);
    }
    .tab-minimized .tab-title span {
        transform: rotate(0deg);
        font-size: 2rem;
    }

    /*****************MOBILE TAB CONTENT*********************/
    .tab-content {
        overflow-y: scroll;
        overflow-x: hidden;

        padding: 2vh 2vw 2vh 2vw;

        pointer-events: stroke;

        width: 100vw;
        height: 100%;
    }

    .tab-overflow-inner {
        height: fit-content;
        position: relative;
    }

    .tab-unopened .tab-content,
    .tab-minimized .tab-content {
        visibility: hidden;
        opacity: 0;
        transform: translateY(3vh);
    }


    .tab-opened .tab-content {
        visibility: visible;
        opacity: 1;
        transform: translateY(0vh);
    }

    /*SCROLL UP BTN*/

    .tab-unopened .scrolltop,
    .tab-minimized .scrolltop{
        visibility: hidden;
        transform: translateY(130%);
    }

    .scrolltop {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5vh;

        position: sticky;
        bottom: 0vh;
        left: 100%;
        background-color: var(--white-bg-font);

        visibility: hidden;

        transition: visibility 600ms, transform 500ms ease-out;

        box-shadow: 0px 2px 3px rgb(0, 0, 0, 0.8);

        transform: translateY(130%);
    }

    .scrolltop img {
        width: 8vw;
        aspect-ratio: 1/1;
    }

    .scroll-btn-display {
        visibility: visible;
        transform: translateY(0%);
    }
}