#contactForm {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1200;

    transition: transform 400ms ease-in-out;
}

#contactForm.contactForm-unopened {
    transform: translateX(-100%);
}

#contactForm.contactForm-opened {
    transform: translateX(0%);
}

#contactFormBG {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    opacity: 0;

    transition: opacity 300ms;

    z-index: 1100;
}

#contactFormBG.contactForm-unopened {
    visibility: collapse;
    opacity: 0;
}

#contactFormBG.contactForm-opened {
    visibility: visible;
    background-color: rgb(0, 0, 0);
    opacity: 0.6;
}


/*FORM MODAL*/

.form-cont {
    position: relative;

    background-color: var(--sidebar);

    width: 60%;
    height: max-content;
    padding: 3vh;

}

.form-cont h2 {
    margin-top: 0;
    font-size: clamp(3.3rem, 2.4vw, 4rem);
}

.form-cont .close-btn {
    font-size: 3rem;
    position: absolute;
    top: 2vh;
    right: 2vh;
}

.form-cont label {
    font-size: clamp(1.4rem, 1.5vw, 1.7rem);
    margin-bottom: 1vh;
}

/* FORM TAG*/

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.5vh;
}

.form-elem {
    width: 48%;
    display: flex;
    flex-direction: column;
}

.form-subject,
.form-msg .form-elem {
    width: 100%;
}

.row-label {
    margin: 0;
}

.form-msg textarea {
    resize: none;
    height: 30vh;
}

.form-submit {
    width: 100%;
    justify-content: center;

    font-size: clamp(1.8rem, 1.7vw, 2.8rem);
}

.submit-button {
    font-weight: 400;
    position: relative;

    box-sizing: border-box;
    width: 100%;

    padding: 1vh 0.3vw;

    font-weight: 500;

    background-color: var(--tab-4);
    color: var(--white-bg-font);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    transition: filter 200ms, transform 200ms;

    user-select: none;
}

.submit-button:hover {
    filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 0.8));
    transform: scale(102%);
}


#contactForm input,
#contactForm textarea {
    padding: 3px;
    color: var(--darkfont);
    font-size: clamp(1.3rem, 1.5vw, 1.6rem);
    font-family: 'Quicksand', sans-serif;
    border-style: solid;
    border-width: 1px;
    border-color: ghostwhite;
}

#contactForm input:focus,
#contactForm textarea:focus {
    outline: none;
    border-color: var(--tab-4);
    border-style: solid;
}

.input-error::placeholder {
    color: red;
    font-weight: 700;
}

.input-error {
    border-color: red !important;
}

.input-error:focus {
    border-color: ghostwhite !important;
}

.input-error:focus::placeholder {
    color: inherit;
    font-weight: inherit;
}

@media screen and (max-width:800px) {
    .contact-form {
        height: 100%;
    }

    .form-cont {
        width: 100%;
        height: 100%;
        padding: 3vh 3vh 4vh 3vh;
    }

    .form-cont .close-btn {
        font-size: 3rem;
        position: absolute;
        top: 2.5vh;
        right: 2vh;
    }


    .form-cont h2 {
        margin-top: 0;
        font-size: clamp(2.6rem, 2vw, 3rem);
    }

    .form-cont label {
        font-size: clamp(1.6rem, 1.8vw, 2rem);
        margin-bottom: 1vh;
    }

    .row-label label {
        margin: 0;
    }

    .form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 2vh;
    }

    .form-msg {
        height: 100%;
    }

    .form-msg textarea {
        resize: none;
        height: 95%;
    }

    .form-submit {
        width: 100%;
        justify-content: center;

        margin-bottom: 6vh;

        font-size: clamp(1.8rem, 1.7vw, 2.8rem);
    }

    .submit-button:hover {
        filter: none;
        transform: scale(100%);
    }

    .submit-button:active {
        filter: drop-shadow(0px 2px 3px rgb(0, 0, 0, 0.8));
        transform: scale(102%);
    }



    #contactForm input,
    #contactForm textarea {
        padding: 6px;
        font-size: clamp(1.3rem, 1.5vw, 1.6rem);
    }


    #contactForm.contactForm-unopened {
        transform: translateX(0%) translateY(-100%);
    }

    #contactForm.contactForm-opened {
        transform: translateX(0%) translateY(0%);
    }

}