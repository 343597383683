.element-taglist{
    display: flex;
    font-size: 2rem;
    margin: 0;
}
    

.element-taglist{
    display: flex;
    column-gap: 1vh;
}

.element-taglist button:hover{
    color: var(--tab-3);
    cursor: pointer;
}


.portfolio-element{
    overflow: hidden;

    display: flex;

    padding-bottom: 3vh;

    flex-direction: column;

    border-bottom: solid 2px var(--tab-3);

    margin-top: 3vh;

    height: auto;

    max-height: 100vh;
    width: 100%;

    transition: max-height 0.5s, padding-bottom 0.5s , margin-top 0.5s, border-bottom 0.5s;

    background-color: var(--white-bg-font);
}

.portfolio-element img{
    width: 100%;
    height: auto;
}

.portfolio-hidden{
    padding: 0px;
    margin: 0px;
    border: solid 0px rgb(255, 255, 255, 0);
    max-height: 0px;
}

/*Portfolio inner styling*/
.portfolio-title{
    text-transform: uppercase;
    font-size: 2.5rem;
    margin: 1vh 0 2vh 0;
    color: var(--tab-3);
}

.portfolio-title a{
    text-decoration: none;
    color: var(--tab-3);
}
.portfolio-title a::after{
    position: relative;
    content: "";
    display: inline-block;
    margin-left: 1rem;
    height: 2rem;
    aspect-ratio: 1/1;
    background-color: var(--tab-3);
    z-index: 0;

    mask: url("./icons/link.svg") no-repeat center / contain ;
}

.portfolio-title a:hover{
    color: var(--tab-3-hover) ;
}
.portfolio-title a:hover::after{
    background-color: var(--tab-3-hover);
}
.portfolio-year{
    margin: 0 0 2vh 1vh;
    font-style: oblique;
    font-weight: 400;
    font-size: 2rem;
}
.portfolio-text{
    line-height: 1.5;
    display: flex;
    justify-content: flex-start;
    column-gap: 2vw;
    font-size: 2rem;

    white-space: pre-line;  
    vertical-align: bottom;
}
.portfolio-subheader{
    display: block;
    font-weight: 600;
    font-size: 2.2rem;
}
.portfolio-text p{
    display: block;
    width: 50%;
}

/***** Portfolio navigation styling *******/
.portfolio-nav{
    display: flex;
    font-size: 2rem;
}

.dropdown-select.portfolio-select{
    width: 40%;
}

.portfolio-select .dropdown-base{
    background-color: var(--white-bg-font);
    border: solid 1px var(--tab-3);
}

.portfolio-select .dropdown-base:hover,
.portfolio-select .dropdown-option:hover{
    background-color: var(--tab-3);
    color: var(--white-bg-font);
}

.portfolio-select .dropdown-active-menu{
    border: solid 1px var(--tab-3);
    border-top: none;
    background-color: var(--white-bg-font);
}

.portfolio-select .dropdown-active-hidden{
    border: none;
}

.portfolio-nav button{
    display: block;
    padding: 0vh 1vw;
    font-weight: 400;
    background-color: var(--white-bg-font);
    border: solid 1px var(--tab-3) ;
    border-left: none;

}

.portfolio-nav button:hover{
    color: var(--white-bg-font);
    background-color: var(--tab-3);
}


@media screen and (max-width:800px) {
    /* Portfolio content for mobile */

   
    .portfolio-element{
        padding: 0% 5% 3vh 5%;
    }

    .portfolio-hidden{
        padding: 0%;
    }


    .portfolio-title{
        display: flex;
        flex-direction: column;
        font-size: 2.3rem;
        margin: 1vh 0 0vh 0;
    }

    .portfolio-title a{
        display: block;
        width: fit-content;
    }

    .portfolio-year{
        margin: 1vh 0 2vh 0vh;
        font-style: oblique;
        font-weight: 400;
        font-size: 1.8rem;
    }

    .portfolio-text{
        flex-direction: column;
        align-items: center;
        font-size: 1.7rem;
        margin-bottom: 2vh;
    }
    .portfolio-subheader{
        display: block;
        font-weight: 600;
        font-size: 2rem;
    }
    .portfolio-text p{
        margin-bottom: 0px;
        width: 100%;
    }

    /***** Portfolio navigation mobile styling *******/
    .dropdown-select.portfolio-select{
        width: 78%;
    }
    .portfolio-nav button{
       width: 18%;
       text-align: center;
    }

    .portfolio-nav button:hover{
        color: var(--tab-3);
        background-color: var(--white-bg-font);
    }

    .portfolio-nav button:active{
        color: var(--white-bg-font);
        background-color: var(--tab-3);
    }

    .portfolio-select .dropdown-base:hover,
    .portfolio-select .dropdown-option:hover{
        background-color: var(--white-bg-font);
        color: var(--tab-3);
    }
    .portfolio-select .dropdown-base:active,
    .portfolio-select .dropdown-option:active{
        background-color: var(--tab-3);
        color: var(--white-bg-font);
    }

    
    
}
