.exp-section-title{
    background-color: var(--tab-2);
    color: var(--white-bg-font);
    text-align: center;
    padding: 1vh;
    margin: 0 0 2vh 0;
    font-size: 3rem;
    text-transform: uppercase;
    text-shadow: 0px 0px 5px rgb(0,0,0,0.4);
}

.exp-section{
    display: flex;
    flex-direction: column;
    row-gap: 2vh;
    padding: 2vh 2vw;
}

.exp-element{
    line-height: 1.75;
    background-color: var(--white-bg-font);
}

.exp-period{
    font-style: oblique;
    font-size: 1.8rem;
    color: var(--tab-2);
    font-weight: 400;
    margin:0;
}
.exp-title{
    font-weight: 600;
    font-size: 2.2rem;
    margin:0;
}
.exp-list li{
    font-size: 1.8rem;
}

#expFormations .exp-section{
    row-gap: 3vh;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

#expFormations .exp-element{
    width: 45%;
}

#expFormations .exp-title{
    font-size: 2.1rem;
}

#expPro{
    margin-bottom: 2vh;
}

@media screen and (max-width:800px){
    .exp-section-title{
        margin: 0 0 2vh 0;
        font-size: 2.5rem;
    }
    
    .exp-section{
        padding: 2vh 2vw;
    }

    .exp-period{
        font-size: 1.5rem;
    }
    .exp-title{
        font-size: 1.9rem;
    }
    .exp-list{
        padding-left: 2vh;
        margin-top: 1vh;
    }
    .exp-list li{
        font-size: 1.6rem;
        margin-bottom: 1vh;
    }

    #expFormations{
        padding: 0vh 2vw;
    }
    
    #expFormations .exp-section{
        row-gap: 3vh;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    #expFormations .exp-element{
        width: 100%;
    }
    
    #expFormations .exp-title{
        font-size: 1.9rem;
    }
    
    #expPro{
        margin-bottom: 1vh;
        padding: 0vh 2vw;
    }
}
