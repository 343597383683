@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
:root{
  font-size: 10px;

  --tab-1: #7999e0;
  --tab-2: #5478cc;
  --tab-3: #5753c9;
  --tab-4: #6d53c9; 

  --tab-3-hover:#c95753;

  --sidebar: #dcd5f7;
  --dark-font: #2c2c2e;

  --white-bg-font: ghostwhite;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

button{
  all:unset;
  cursor: pointer;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100vw;
  height: 100vh;

  font-family: 'Quicksand', sans-serif;

  /* user-select: auto; */

  color: var(--dark-font);
}


#root{
  display: flex;
  width: 100%;
  height: 100%;
}


main{
  position: relative;
  width: 82%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  z-index: 200;

}

@media screen and (-webkit-device-pixel-ratio: 2) {
  :root{
    font-size: 8px;
  }
}
@media screen and (-webkit-device-pixel-ratio: 1.5) {
  :root{
    font-size: 9px;
  }
}
@media screen and (-webkit-device-pixel-ratio: 1.25) {
  :root{
    font-size: 9px;
  }
}

/********************************************** Mobile CSS**************************************/


@media screen and (max-width: 800px) {
  html,body{
    overscroll-behavior: none;
  }

  #root{
    flex-direction: column;
  }

  main{
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media screen and (min-width: 540px) and (max-width:800px) {
  :root{
    font-size: 12px;
  }
}