.skills-cont{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    padding: 0vh 2vw;
    row-gap: 2vh;
}

.skills-element{
    width: 47%;
    background-color: var(--white-bg-font);
}

.skills-element h3{
    user-select: none;
    text-align: center;
    font-size: 3rem;
    margin: 0vh 0vw 1vh 0vw;
    padding: 1vh 0vw;
    background-color: var(--tab-4);
    color: var(--white-bg-font);
    text-shadow: 0px 0px 5px rgb(0,0,0,0.4);
}

.skills-content{
    padding: 0vh 1vw;
    font-size: 2rem;
    line-height: 1.5;
    margin: 0px;

    height: auto;
    max-height: 100vh;

    transition: max-height 0.5s;
    overflow: hidden;
}

.skills-content ul{
    padding: 0vh 2vw;
    list-style:circle;
}

.skills-content li{
    margin-bottom: 1vh;
}


@media screen and (max-width:800px) {
    .skills-cont{
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        flex-direction: column;

        row-gap: 2vh;
    }
    .skills-hidden{
        height: auto;
        max-height: 0px;    
    }
    .skills-element{
        width: 100%;
    }
    .skills-content ul{
        padding: 0vh 0vh 0vh 4vh;
    }
    .skills-element h3{
        font-size: 2.6rem;
    }
    .skills-content{
        font-size: 1.8rem;
    }

    .skills-element h3:active{
        background-color: var(--sidebar);
        color: var(--dark-font);
        text-shadow: none;
    }
}